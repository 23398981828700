.gpt3__cta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 2rem;
  margin: 4rem;
  background: var(--gradient-bar);
  box-shadow: -24px 20px 5px 0px rgba(77, 44, 44, 0.75);
  -webkit-box-shadow: -24px 20px 5px 0px rgba(143, 76, 160, 0.75);
  -moz-box-shadow: -24px 20px 5px 0px rgba(0, 0, 0, 0.75);
  border-radius: 1rem;
}

.gpt3__cta-content p {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 12px;
  line-height: 30px;
  /* or 250% */

  color: #0e0e0e;
}

.gpt3__cta-content h3 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 24px;
  line-height: 45px;
  /* or 188% */

  color: #000000;
  /* or 188% */
}

.gpt3__cta-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
}

.gpt3__cta-btn button {
  background: #000000;
  border-radius: 40px;
  padding: 1rem;
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  /* identical to box height, or 167% */

  color: #ffffff;
  padding: 0.5rem 1rem;
  border: none;
  outline: none;
  cursor: pointer;
  min-width: 150px;
}

@media screen and (max-width: 950px) {
  .gpt3__cta {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .gpt3__cta-btn {
    margin: 1rem 0;
  }
}
