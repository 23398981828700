.gpt3__blog {
  display: flex;
  flex-direction: column;
}

.gpt3__blog-heading {
  width: 100%;
  text-align: left;
}
.gpt3__blog-heading h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 62px;
  line-height: 75px;
  /* or 121% */

  letter-spacing: -0.04em;

  background: -webkit-linear-gradient(#f3ec78, #af4261);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.gpt3__blog-container {
  display: flex;
  flex-direction: row;
}

.gpt3__blog-container_groupA {
  flex: 0.75;
  margin-right: 2rem;
}

.gpt3__blog-container_groupB {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
}

@media screen and (max-width: 990px) {
  .gpt3__blog-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .gpt3__blog-container_groupA {
    margin: 2rem 0;
  }
}

@media screen and (max-width: 700px) {
  .gpt3__blog-container_groupB {
    grid-template-columns: repeat(1, 1fr);
  }
  .gpt3__blog-heading h1 {
    font-size: 46px;
    line-height: 52px;
  }
}
